import React from 'react'
import { Link } from 'gatsby'
import { Menu, Icon } from 'antd'

const menuItems = [
  {
    key: 'home',
    icon: 'home',
    text: 'Home',
    link: '/',
  },
  {
    key: 'resume',
    icon: 'file-text',
    text: 'Resume',
    link: '/resume',
  },
  {
    key: 'contact',
    icon: 'mail',
    text: 'Contact',
    link: '/contact',
  },
]

const AppMenu = ({ current }) => {
  const selectedKeys = menuItems.filter(item => item.link === current).map(item => item.key)
  return (
    <Menu mode='horizontal' style={{ marginBottom: '1.45rem' }} selectedKeys={selectedKeys}>
      {menuItems.map(item =>
        <Menu.Item key={item.key}>
          <Link to={item.link}>
            <Icon type={item.icon} />{item.text}
          </Link>
        </Menu.Item>
      )}
    </Menu>
  )
}

export default AppMenu
